// contratacion-config.service.js
"use strict";

class contratacionConfig {
    constructor($q, authService) {
      //control p0
        this.$q = $q;
        this.authService = authService;
        this.SEL_P0 = 'selP0';
        this.SEL_TRARIFA = 'selTarifa';
        this.SEL_LUZ = 'selLuz';
        this.SEL_LLAMAMOS_LUZ = 'selLlamamosLuz';
        this.SEL_BONO_SOCIAL = 'selBonoSocial';
        this.SEL_SERVICIOS = "selServicios";
        this.FORM_LLAMAMOS_LUZ = 'selFormLlamamosLuz';
        this.CONFIRM_BONO_SOCIAL = 'selConfirmBonoSocial';
        this.FORM_DATOS_PERSONALES = 'selDatosPersonales';
        this.FORM_DATOS_DOMICILIO = 'selDatosDomicilio';
        this.FORM_DOMICILIO_CUPS = 'selDomicilioCups';
        this.FORM_METODO_PAGO = 'selMetodoPago';
        this.FORM_RESUMEN_PEDIDO = 'selResumenPedido';
        this.FORM_FIRMA_DOCUMENTOS = 'selFirmaDocumentos';
        this.FORM_ENVIO_FACTURAS = 'selEnvioFacturas';
        this.SCORING = 'selScoring;'
        this.GENERANDO_CONTRATO = 'selGenerandoContrato';
        this.VALIDACION_TIPO_CLIENTE = 'validacionTipoCliente';
        this.baseConfig = this.getBaseConfig();
        this.telephoneAgentConfig = this.getTelephoneAgentConfig();
        this.inPersonAgentConfig = this.getInPersonAgentConfig();
    }

    getConfig() {
        let deferred = this.$q.defer();
        deferred.resolve(this.authService.isTelephoneAgent() && this.telephoneAgentConfig || this.inPersonAgentConfig);
        return deferred.promise;
    }

    getBaseConfig() {
        return {
            [this.SEL_P0]: {
                id: this.SEL_P0,
                pregunta: 'Introduce los siguientes datos del cliente y de su punto de suministro',
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Necesitamos esta información para validar que el cliente es el titular del punto de suministro'
                    }
                },
                numeroPaso: 1,
                pasoSiguiente: [this.SEL_LUZ]
            },
            [this.SEL_LUZ]: {
                id: this.SEL_LUZ,
                pregunta: '¿Tiene luz en casa?',
                informacion: 'Indica si actualmente el cliente tiene luz en casa o si habría que dar de alta el suministro eléctrico por primera vez',
                multiseleccion: false,
                respuestas: [
                    {
                        texto: 'Si',
                        seleccionada: false,
                        icono: 'V'
                    },
                    {
                        texto: 'No',
                        seleccionada: false,
                        icono: 'X'
                    }
                ],
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Si el cliente no tiene luz en casa no es posible realizar la contratación en este momento.'
                    }
                },
                numeroPaso: 2,
                pasoSiguiente: [this.SEL_BONO_SOCIAL, this.FORM_LLAMAMOS_LUZ]
            },
            [this.SEL_LLAMAMOS_LUZ]:{
                id: this.SEL_LLAMAMOS_LUZ,
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Si todavía no tienes luz en tu domicilio, te detallamos lo necesario para que estés en Energía El Corte Inglés cuanto antes'
                    }
                },
                numeroPaso: 2
            },
            [this.SEL_BONO_SOCIAL]: {
                id: this.SEL_BONO_SOCIAL,
                pregunta: '¿Es beneficiario del Bono Social?',
                informacion: 'Selecciona una de estas dos opciones',
                multiseleccion: false,
                respuestas: [
                    {
                        texto: 'Si',
                        seleccionada: false,
                        icono: 'V'
                    },
                    {
                        texto: 'No',
                        seleccionada: false,
                        icono: 'X'
                    }
                ],
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Para nosotros es muy importante que contrate la tarifa que más le convenga. Por eso preguntamos si tiene Bono Social ya que si es así, nosotros no podremos ofrecerlo.'
                    }
                },
                numeroPaso: 3,
                pasoSiguiente: [this.CONFIRM_BONO_SOCIAL, this.VALIDACION_TIPO_CLIENTE]
            },
            [this.CONFIRM_BONO_SOCIAL]:{
                id: this.CONFIRM_BONO_SOCIAL,
                pregunta: '¿Está interesado a continuar con la contratación renunciando al bono social?',
                informacion: 'Selecciona una de estas opciones:',
                multiseleccion: false,
                respuestas: [
                    {
                        texto: 'Si',
                        seleccionada: false,
                        icono: 'V'
                    },
                    {
                        texto: 'No',
                        seleccionada: false,
                        icono: 'X'
                    }
                ],
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Estaremos encantados de recibirle en Energía El Corte Inglés, pero queremos que el cliente también esté seguro ya que con nosotros no podrá mantener el bono social'
                    }
                },
                numeroPaso: 3,
                pasoSiguiente: [this.VALIDACION_TIPO_CLIENTE]
            },
            [this.VALIDACION_TIPO_CLIENTE]:{
                id: this.VALIDACION_TIPO_CLIENTE,
                pregunta: 'Validación tipo de cliente',
                informacion: '¿El cliente es empleado del grupo ECI?',
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: '<p>Con el número de TECI podemos hacer la comprobación de que es empleado, y por tanto, tener acceso a la exclusividad de nuestras tarifas y promociones.</p>Si no tiene TECI pero es empleado, podrá llamarnos y contratar de forma telefónica.'
                    }
                },
                numeroPaso: 4,
                pasoSiguiente: [this.SEL_TRARIFA]
            },
            [this.SEL_TRARIFA]:{
                id: this.SEL_TRARIFA,
                pregunta: 'Selecciona la tarifa a contratar',
                ayuda: {
                    ayuda: {
                        titulo: 'Detalle de precios de las tarifas ofertadas',
                        texto: 'Pulsando en cada una de las tarifas podrá consultar el detalle completo de los precios de potencia y energía, incluso el precio final con impuestos, así como las franjas horarias en el caso de varios tramos.'
                    }
                },
                numeroPaso: 5,
              pasoSiguiente: [this.SEL_SERVICIOS,this.FORM_DATOS_PERSONALES]
            },
            [this.SEL_SERVICIOS]:{
                id: this.SEL_SERVICIOS,
                pregunta: '¿Deseas algún servicio adicional?',
                ayuda: {
                    ayuda: {
                        titulo: '¿Cómo pagar estos servicios? ',
                        texto: 'Estos servicios son independientes a la factura de luz. Se te aplicará un coste adicional en función del servicio contratado, que se mostrará en tu factura.'
                    }
                },
                numeroPaso: 6,
                pasoSiguiente: [this.FORM_DATOS_PERSONALES]
            },
            [this.FORM_DATOS_PERSONALES]:{
                id: this.FORM_DATOS_PERSONALES,
                ayuda: {
                    ayuda: {
                        titulo: '¿Para qué necesitamos estos datos?',
                        texto: 'Estos son los datos personales que aparecerán en el contrato y que utilizaremos cuando contactemos con el ciente'
                    }
                },
                numeroPaso: 7,
                pasoSiguiente: [this.FORM_DATOS_DOMICILIO]
            },
            [this.FORM_DATOS_DOMICILIO]:{
                id: this.FORM_DATOS_DOMICILIO,
                ayuda: {
                    alerta: {
                        titulo: 'Recuerda informar sobre el derecho de desistimiento',
                        texto: 'Recordar al cliente que con tal de maximizar la agilidad y permitirle disfrutar de los beneficios de Energía El Corte Inglés lo antes posible, aconsejamos solicitar el inicio de los trámites para gestionar el suministro antes de que se finalice el plazo para el ejercicio del derecho de desistimiento (14 días)'
                    },
                    info: {
                        titulo: 'Información sobre potencia',
                        texto: 'Estás contratando con la misma potencia que tienes en tu comercializadora actual. Si quireres cambiar tu potencia te ayudaremos a elegir la más óptima para ti cuando tu contrato esté activo.'
                    }
                },
                numeroPaso: 8,
                pasoSiguiente: [this.FORM_DOMICILIO_CUPS]
            },
            [this.FORM_DOMICILIO_CUPS]:{
                id: this.FORM_DOMICILIO_CUPS,
                numeroPaso: 7,
                pasoSiguiente: [this.FORM_ENVIO_FACTURAS]
            },
            [this.FORM_ENVIO_FACTURAS]:{
                id: this.FORM_ENVIO_FACTURAS,
                ayuda: {
                    ayuda: {
                        titulo: 'Distintas opciones de envío',
                        texto: '<p>Es recomendable el envío de las facturas por email. En esta pantalla podrá modificar los datos facilitados.</p>En el caso que el cliente prefiera el envío postal puede especificar una dirección diferente a la facilitada para el suministro.'
                    }
                },
                numeroPaso: 9,
                pasoSiguiente: [this.FORM_METODO_PAGO]
            },
            [this.FORM_METODO_PAGO]:{
                id: this.FORM_METODO_PAGO,
                ayuda: {
                    ayuda: {
                        titulo: '¿Para qué necesitamos estos datos?',
                        texto: 'Puede elegir donde quiere que facturemos la luz que utilice. Podrá cambiar el método de pago en el futuro desde su área de clientes personalizada'
                    },
                    alertapagador: {
                        titulo: 'Contrato con dos firmantes: Titular/Pagador',
                        texto: '<p>Recuerda al cliente que éste contrato genera dos firmas, una correspondiente al titular y otra al pagador. Ambas tienen que ser firmadas para poder completar el contrato.</p><p>Si se da el caso de que, aún siendo dos personas físicas diferentes, utilizan el mismo email y teléfono de contacto, igualmente recibirán en el email de contacto dos correos consecutivos y cada uno de ellos debe ser firmado.</p>'
                    }
                },
                numeroPaso: 10,
                pasoSiguiente: [this.FORM_RESUMEN_PEDIDO]
            },
            [this.FORM_RESUMEN_PEDIDO]:{
                id: this.FORM_RESUMEN_PEDIDO,
                numeroPaso: 11,
                pasoSiguiente: [this.FORM_FIRMA_DOCUMENTOS]
            },
            [this.FORM_FIRMA_DOCUMENTOS]:{
                id: this.FORM_FIRMA_DOCUMENTOS,
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos estos documentos?',
                        texto: 'Esta documentación es opcional, ya que tan solo aporta mayor información sobre el cliente.'
                    },
                    info: {
                        titulo: 'Documentos permitidos',
                        texto: 'Se pueden adjuntar únicamente documentos de tipo: <strong>pdf</strong>, <strong>jpg</strong>, <strong>jpeg</strong> y <strong>png</strong>'
                    }
                },
                numeroPaso: 12
            },
            textos: {
                contratacion: {
                    mensajesAlert: {
                        noTieneLuzEnCasa: {
                            titulo: 'Agradece al cliente su confianza en Energía El Corte Inglés',
                            mensaje: 'Esperamos que en un futuro podamos dar de alta su punto de suministro',
                            textoBoton: 'Iniciar nuevo contrato'
                        },
                        bonoSocial: {
                            titulo: 'Agradece al cliente su confianza en Energía El Corte Inglés',
                            mensaje: 'Lamentablemente en la actualidad no podemos ofrecerle una tarifa que mejore las ventajas de su Bono Social',
                            textoBoton: 'Iniciar nuevo contrato'
                        },
                        dialogoMensaje: {
                            titulo: 'Agradece al cliente su confianza en Energía El Corte Inglés',
                            textoBoton: 'Iniciar nuevo contrato',
                        },
                        checkScoring: {
                            titulo: 'Ups... Parece que algo nos impide continuar con el proceso',
                            mensaje: 'Ha sucedido un error con el sistema de credit check. Comunica al cliente que una incidencia no nos deja continuar y recoge su contacto para seguir con el proceso telefónicamente',
                            txtNombre: 'Escribe su nombre y apellidos',
                            txtTelefono: 'Escribe su número de teléfono',
                            txtBoton: 'Enviar'
                        },
                        errorComprobandoCups: {
                            error404: {
                                titulo: 'Información de CUPS no encontrada',
                                mensaje: 'Comunica al cliente que <strong>no se ha encontrado información de su CUPS</strong> y <strong>es necesario informar de la potencia</strong>',
                                txtBoton: 'Añadir potencia'
                            },
                            error409: {
                                titulo: 'Ups... Parece que algo nos impide continuar con el proceso',
                                mensaje: 'Comunica al cliente que <strong>la potencia no se corresponde con el producto</strong> y recoge su contacto para seguir con el proceso telefónicamente'
                            },
                            default: {
                                titulo: 'Ups... Parece que algo nos impide continuar con el proceso',
                                mensaje: 'Comunica al cliente que <strong>no hemos podido validar su código CUPS</strong> y recoge su contacto para seguir con el proceso telefónicamente'
                            }
                        },
                        mostrarDialogoError: {
                            dialogoLead: {
                                titulo: 'Ups... Parece que algo nos impide continuar con el proceso'
                            },
                            dialogoMensaje: {
                                titulo: '¡Lo sentimos!',
                                mensaje: '<strong>El cliente no ha superado el credit check - Infórmale de que ha habido una incidencia en el sistema que no nos deja proseguir</strong> con el contrato por <strong>motivos ajenos a Energía El Corte Inglés.</strong> En un futuro, si sigue interesado, puede volver a ponerse en contracto con nosotros'
                            }
                        }
                    },
                    mensajesLead: {
                        contratar: 'Ha sucedido un error al generar el contrato. Comunica al cliente que una incidencia no nos deja continuar y recoge su contacto para seguir con el proceso telefónicamente',
                        documentos: 'Ha sucedido un error al almacenar los documentos. Comunica al cliente que una incidencia no nos deja continuar y recoge su contacto para seguir con el proceso telefónicamente',
                        guardandoDatos: 'Ha sucedido un error al almacenar los datos del contrato. Comunica al cliente que una incidencia no nos deja continuar y recoge su contacto para seguir con el proceso telefónicamente'
                    }
                },
                datosDomicilio: {
                    textoDialogoTeLlamamosCups: {
                        ayuda: '¿Necesita ayuda?',
                        titulo: '¿Problemas con el CUPS?',
                        mensaje: 'Introduce los datos y en breve le atenderá un comercial de Energía El Corte Inglés'
                    },
                    textoDialogoTeLlamamosPotencia: {
                        ayuda: '¿Necesita ayuda?',
                        titulo: '¿Problemas con la Potencia?',
                        mensaje: 'Introduce los datos y en breve le atenderá un comercial de Energía El Corte Inglés'
                    },
                    esViviendaHabitual: '¿Es su vivienda habitual?'
                },
                metodoPago: {
                    textoDialogoTeLlamamosIban: {
                        ayuda: '¿Necesita ayuda?',
                        titulo: '¿Problemas con el IBAN?',
                        mensaje: 'Introduce los datos y en breve le atenderá un comercial de Energía El Corte Inglés'
                    },
                    textoDialogoEditarDatosDomicilio: {
                        titulo: 'Introduce la dirección de facturación'
                    }
                }
            }
        };
    }

    getInPersonAgentConfig() {
        let baseConfigCopy = Object.assign({}, this.baseConfig);
        return Object.assign(baseConfigCopy, {

        });
    }

    getTelephoneAgentConfig() {
        let baseConfigCopy = Object.assign({}, this.baseConfig);
        return Object.assign(baseConfigCopy, {
          [this.VALIDACION_TIPO_CLIENTE]:{
              id: this.VALIDACION_TIPO_CLIENTE,
              pregunta: 'Validación tipo de cliente',
              informacion: '¿El cliente es empleado del grupo ECI?',
              ayuda: {
                  ayuda: {
                      titulo: '¿Por qué pedimos esto?',
                      texto: 'Con el número de TECI o número de empleado podemos hacer la comprobación de que es empleado, y por tanto, tener acceso a la exclusividad de nuestras tarifas y promociones.'
                  }
              },
              numeroPaso: 4,
              pasoSiguiente: [this.SEL_TRARIFA]
          }
        });
    }
}

contratacionConfig.$inject = ["$q", "authService"];

export { contratacionConfig as CONTRATACION_CONFIG_SERVICE };
