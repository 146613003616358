// auth.interceptor.js
/* globals apiKey, withTrace */
"use strict"

function authInterceptor($window) {
    return {
        request: function(config) {
            config.headers = config.headers || {};
          //Cuando se haga una petición a una API que no esté dentro de la api manager hay que meterle por header el valor External a true para que no entre en la interceptación
          if (config.headers['External'] !== true) {
            config.headers['Ocp-Apim-Subscription-Key'] = apiKey;
            config.headers['Ocp-Apim-Trace'] = withTrace;
            let token = $window.sessionStorage.getItem('sweno-agent-token-validation');
            if (token) {
              config.headers['Authorization'] = 'Bearer ' + token;
            }
          }
            return config;
        },
        responseError: function(response) {
            switch (response.status) {
                case 401:
                  $window.location.href = '/login';
                  return;
                default:
                    return response;
            }
        }
    };
}

authInterceptor.$inject = ["$window"];

export { authInterceptor };
